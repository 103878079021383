import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscriber, Subscription, map, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

import { httpmethod } from '../global';
@Injectable({
  providedIn: 'root'
})
export class BasudevayService {
  constructor(private http: HttpClient) {
  }
  requestapi(requesttype: string, apiURL: string, model?: object, models?: object[], id?: number,httpparams?:HttpParams, httpheaders?: HttpHeaders): Observable<object> {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    // let httpparams = new HttpParams({
    //   fromObject: {
    //     'email': 'brajendra.k@marg.ind.in',
    //     'password': 'yukti@1234'
    //   }
    // })

    // const headers = new HttpHeaders(headerDict);
    const headers = httpheaders == null ? new HttpHeaders(headerDict) : httpheaders;
    if (requesttype == httpmethod.post) {
      return this.createobj(requesttype, apiURL, model, headers, httpparams)
    }
    else if (requesttype == httpmethod.post && models.length > 0) {
      return this.createobj(requesttype, apiURL, models, headers, httpparams);
    }
    else if (requesttype == httpmethod.put) {
      return this.updateobj(requesttype, apiURL, model, headers, httpparams);
    }
    else if (requesttype == httpmethod.patch) {
      return this.updateobj(requesttype, apiURL, model, headers, httpparams);
    }
    else if (requesttype == httpmethod.delete) {
      return this.deleteobj(requesttype, apiURL, id);
    }
    else if (requesttype == httpmethod.get && id != null && id != undefined) {

      return this.getallobjbyid(requesttype, apiURL, id);
    }
    else if (requesttype == httpmethod.get) {
      return this.getallobj(requesttype, apiURL,httpparams);
    }

    return null;
  }

  createobj(requesttype: string, apiURL: string, model?: object, headers?: HttpHeaders, httpparams?: HttpParams): Observable<object> {
    return this.http.post(environment.apiURL + apiURL, model, { headers: headers, params: httpparams }).pipe(map((res) => {
      console.log(res);
      return res;
    }));
  }
  updateobj(requesttype: string, apiURL: string, model?: object, headers?: HttpHeaders, httpparams?: HttpParams): Observable<object> {
    return this.http.put(environment.apiURL + apiURL, model, { headers: headers, params: httpparams }).pipe(map((res) => {
      console.log(res);
      return res;
    }), catchError((error) => { return throwError(error) }));;

  }
  deleteobj(requesttype: string, apiURL: string, id: number, model?: object, headers?: HttpHeaders, httpparams?: HttpParams): Observable<object> {
    return this.http.delete(environment.apiURL + apiURL + '/' + id).pipe(map((res) => {
      console.log(res);
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  getallobjbyid(requesttype: string, apiURL: string, id: number, httpparams?: HttpParams): Observable<object> {
    return this.http.get(environment.apiURL + apiURL + '/' + id).pipe(map((res) => {

      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  getallobj(requesttype: string, apiURL: string,httpparams?:HttpParams): Observable<object> {
    return this.http.get(environment.apiURL + apiURL,{params:httpparams}).pipe(map((res) => {

      return res;
    }), catchError((error) => { return throwError(error) }));
  }
 
}

