import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasudevayService } from './basudevay.service';
import { AlertService } from './alert.service';
import { httpmethod, pages } from '../global';
import { UserProfile } from '../user-profile/user-profile';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Forgetpassword } from '../account/forgetpassword/forgetpassword';
import { School } from '../companyprofile/school/school';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  userprofile: UserProfile
  menuItems: any = [];
  response: any;
  children: any = [];
  loggedin = new BehaviorSubject(false);
  usrname = new BehaviorSubject<string>('');
  school = new BehaviorSubject<School>(null);

  constructor(private basudevayservice: BasudevayService, private http: HttpClient,private router:Router) {
    this.menuItems = new BehaviorSubject<any>([]);

  }
  tree = { id: 0, name: "root", children: [] };

  addChild = (obj, parent) => {
    if (obj.parentid === parent.id) {
      parent.children.push({ ...obj, children: [] });
    } else {
      parent.children.forEach((item) => this.addChild(obj, item));
    }
  };
  buildTree = (arr) => arr.forEach(obj => this.addChild(obj, this.tree));

  login(credentials: { username: string, password: string }) {
    //var headers = new HttpHeaders({ 'userdb': credentials.username });
    var headers = new HttpHeaders({ 'username': credentials.username });

    return this.http.post<any>(environment.apiURL + pages.login, credentials, { headers: headers }).pipe(
      map(user => {
        if (user && user.token) {
          localStorage.setItem('currentuser', JSON.stringify(user));
          this.getmenubyuser(credentials);
          this.getcompany();

        }
        this.usrname.next(JSON.parse(localStorage.getItem('currentuser')).firstname)
        return user;
      })
    )

  }
  getcompany() {
    this.basudevayservice.requestapi(httpmethod.get, pages.getallschool).pipe().subscribe(
      (res: School) => {
        this.school.next(res[0]);
        
        localStorage.setItem('school', JSON.stringify(res[0]));
      });

  }
  getmenubyuser(credentials: { username: string, password: string }) {
    this.tree.children = [];
    this.http.post<any>(environment.apiURL + pages.getmenubyuser, credentials).subscribe((data: any[]) => {
      data.sort((f, n): number => {
        if (f.id < n.id) return -1;
        if (f.id > n.id) return 1;

        return 0;
      });
      this.buildTree(data);
      console.log(this.tree.children);

      this.menuItems.next(this.tree.children);
      this.loggedin.next(true);

    },
      (error) => {

      },
      () => {

      });
  }
  getdefaultmenu() {
    this.tree.children = [];
    this.http.get<any>(environment.apiURL + pages.getdefaultmenu).subscribe((data: any[]) => {
      data.sort((f, n): number => {
        if (f.id < n.id) return -1;
        if (f.id > n.id) return 1;

        return 0;
      });
      this.buildTree(data);
      console.log(this.tree.children);
      this.menuItems.next(this.tree.children);
    },
      (error) => {

      },
      () => {

      });
  }
  logout() {
    localStorage.removeItem('currentuser');
    this.loggedin.next(false);
    this.router.navigate(['/login'],{queryParams:{returnUrl:this.router.routerState.snapshot.url}});

    
  }
  forgetpassword(forgetpassword: Forgetpassword) {
    return this.http.put<any>(environment.apiURL + pages.forgetpassword, forgetpassword).pipe(
      map(user => {
        if (user && user.token) {
          localStorage.setItem('currentuser', JSON.stringify(user));
        }

        return user;
      })
    )
  }
  validateotp(
    otpPassword: string,
  ) {
    return this.http.get<any>(environment.apiURL + pages.validateotp + '/' + otpPassword).pipe(
      map(otp => {
        if (otp) {
          localStorage.setItem('otp', JSON.stringify(otp));


        }

        return otp;
      })
    )

  }
  getotp(mobileNo: string,
    email?: string,
  ) {
    return this.http.post<any>(environment.apiURL + pages.addandsendotp, {
      mobileNo,
      email
    }).pipe(
      map(otp => {
        if (otp) {
          localStorage.setItem('otp', JSON.stringify({
            mobileNo,
            email
          }));


        }

        return otp;
      })
    )

  }
}
