import { Component, Input, OnInit } from '@angular/core';
import { School } from 'src/app/companyprofile/school/school';
import { httpmethod, pages } from 'src/app/global';
import { BasudevayService } from 'src/app/service/basudevay.service';
import { CrossComponentService } from 'src/app/service/crosscomponent.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  school: School;

  constructor(private basudevayservice: BasudevayService, private crosscomponentservice: CrossComponentService) {
    
   }
  ngOnInit(): void {
    this.getschool();

  }

  getschool() {
    this.crosscomponentservice.school.subscribe((res) => {
       this.school = res;
       
      });
    
  }



}
