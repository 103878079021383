import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentComponent } from './student/student.component';
import { StaffComponent } from './staff/staff.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LeavemanagementComponent } from './leavemanagement/leavemanagement.component';
import { TransportComponent } from './transport/transport.component';
import { DepartmentComponent } from './department/department.component';
import { FeetypeComponent } from './feetype/feetype.component';
import { DesignationComponent } from './designation/designation.component';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { ViaComponent } from './via/via.component';
import { UploadhomeimageComponent } from './uploadhomeimage/uploadhomeimage.component';
import { MarksComponent } from './marks/marks.component';

@NgModule({
  declarations: [
    StaffComponent,
    StudentComponent,
    LeavemanagementComponent,
    TransportComponent,
    DepartmentComponent,
    FeetypeComponent,
    DesignationComponent,
    ViaComponent,
    UploadhomeimageComponent,
    MarksComponent
  ],
  imports: [
    CommonModule,    
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule, 
    MatTableModule,
    MatPaginatorModule,
    MaterialTimePickerModule,
    MatExpansionModule,
    MatDividerModule,
    MatMenuModule
   
  ]
})
export class MasterModule { }
