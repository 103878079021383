
<table class="example-full-width">
    <tr>
        <td>
            <div><u>Address</u><Br>
{{school?.address}}
                <!-- Khasro No-571,HNo-06,Gali No-01<Br>
                Chipiyana Buzurg<br>
                Pin Code - 201009 -->

            </div>
        </td>

        <td>
            <div><u>Quick Links</u>
                <ul>
                    <!-- <li><a routerLink="/admission/registration">Registration</a></li> -->
                    <li><a routerLink="/admission/feestructure">Fee Structure</a></li>
                    <li><a routerLink="/admission/enquiry">Enquiry</a></li>
                    <li><a routerLink="/admission/admission">Admission</a></li>
                </ul>
            </div>
        </td>

        <td>
            <div><u>Location</u>
                <br>
                {{school?.address}}
                <!-- Khasro No-571,HNo-06,Gali No-01<Br>
                Chipiyana Buzurg<br>
                Pin Code - 201009 -->
            </div>
        </td>

        <td>
            <div><u>Contact Us</u>
                <br>
              Mob no.-  {{school?.phoneno}} <Br>
              Emailid-      {{school?.emailid}}
                <!-- Mobile No-09845632547 <Br>
                Emailid -school@gmail.com<br> -->
                <p class="blink"> No admission charges. </p>
            </div>
        </td>

    </tr>
</table>
<table cellspacing="0" cellpadding="0">
    <tr class="copyright" >
        <td>Yukti IT Services ©2024  All Rights Reserved | Privacy Policy</td>
        <td>Mob No. 9310704699 For Demo</td>
        <td>Company Email Id - yuktiadhyamails@gmail.com </td>
        <td><u>Company Address</u><br>
            Ghaziabad, Gautam Budh Nagar
            <!-- Khasro No-571,HNo-06,Gali No-01<Br>
            Chipiyana Buzurg<br>
            Pin Code - 201009  -->
        </td>
    </tr>
</table>