<!-- <section [class.sidenav]="isExpanded">
    <div class="toggle">
      <mat-icon (click)="toggleMenu.emit(null)">
        {{ isExpanded ? "keyboard_backspace" : "dehaze" }}
      </mat-icon>
    </div>

    <mat-list class="nav" *ngFor="let menuItem of menuItems">
      <a
        mat-list-item
        routerLinkActive="active-link"
        class="hover"
        routerLink="{{ menuItem.Path }}"
      >
        <mat-icon
          mat-list-icon
          [matTooltip]="!isExpanded ? menuItem.Title : null"
          matTooltipPosition="right"
        >
          {{ menuItem.Icon }}</mat-icon
        >
        <p matLine *ngIf="isExpanded">{{ menuItem.Title }}</p>
      </a>
    </mat-list>
  </section> -->

<mat-toolbar color="primary">
  <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-card class="example-card">

    <!-- <div mat-card-avatar class="example-header-image"></div> -->

    <div>
    <img src="{{imgsrc}}" alt="Red dot" />
  </div>

  </mat-card>

  <span> {{school?.name}}</span>
  <span class="toolbar-item-spacer"></span>
  

  <marquee direction="left" style="font-family: Rajdhani, sans-serif; color: #FFFFFF" scrolldelay="100" behavior="loop">No admission charges</marquee>
  
  <!-- <button mat-icon-button matTooltip="Like">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Share">
    <mat-icon>share</mat-icon>
  </button> -->
  <button mat-icon-button [matBadge]="matbadge" [matBadgeHidden]="hidden" (click)="toggleBadgeVisibility()">
    <mat-icon>notifications</mat-icon>
  </button>
  {{username}}
  
   
    Welcome {{welcomeusername}}

  <!-- <button mat-icon-button matTooltip="Notifications">
    <mat-icon>notifications</mat-icon>
  </button> -->
  <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="userAccountMenu">
    <mat-icon>account_circle</mat-icon>
   
  </button>        
  

  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
    <button mat-menu-item  (click)="getaccoutdialog('0ms','0ms')">
      <mat-icon>person</mat-icon><span>My Account</span>
    </button>
    <!-- <button mat-menu-item routerLink="#">
      <mat-icon>settings</mat-icon><span>Settings</span>
    </button> -->
    <!-- <button mat-menu-item routerLink="#">
      <mat-icon>help</mat-icon><span>Help</span>
    </button> -->
    <mat-divider></mat-divider>
    <button mat-menu-item routerLink="/login" (click)="logout()" *ngIf="loginstatus">
      <mat-icon>exit_to_app</mat-icon>Logout
    </button>
    <button mat-menu-item routerLink="/login" *ngIf="!loginstatus">
      <mat-icon>exit_to_app</mat-icon>Login
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container color="primary" class="app-container" >
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z0]="true" class="app-sidenav">
    <mat-nav-list *ngFor="let menuItem of menuItems">
      <mat-list-item style="padding-left: 43px;" *ngIf="menuItem.children.length==0" routerLink="{{menuItem.path}}">
       <span>{{menuItem.title}}</span>
    </mat-list-item>
    <!-- <mat-icon>{{menuItem.icon}}</mat-icon> -->
      <mat-expansion-panel *ngIf="menuItem.children.length > 0" >
        <mat-expansion-panel-header>
          <mat-list-item routerLink="[menuItem.path]">
            <span>{{menuItem.title}}</span></mat-list-item> 
        </mat-expansion-panel-header>
        <!-- <mat-icon>{{menuItem.icon}}</mat-icon> -->
        <mat-nav-list *ngFor="let children of menuItem.children">
          <mat-list-item style="padding-left: 48px;" routerLink="{{children.path}}">
           <span>{{children.title}}</span>
          </mat-list-item>
        </mat-nav-list>
        <!-- <mat-icon>{{children.icon}}</mat-icon> -->
      </mat-expansion-panel>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet>
      <!--for below div  class="app-sidenav-content"-->
      <div >
      
        <!-- Components open here -->
      </div>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<!-- <mat-expansion-panel [class.mat-elevation-z0]="true" *ngFor="let menuItem of menuItems">
                <mat-expansion-panel-header>
                    Mobile Phone
                </mat-expansion-panel-header>
                <mat-nav-list *ngFor="let children of menuItem.children">
                    <a mat-list-item routerLink="[children.Path]">
                        <mat-icon>{{children.Icon}}</mat-icon><span>{{children.Title}}</span>
                    </a>
                    
                </mat-nav-list>
            </mat-expansion-panel>             -->