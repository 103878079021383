import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';

import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';

import { AssigntransportComponent } from './assigntransport/assigntransport.component';


@NgModule({
  declarations: [AssigntransportComponent],
  imports: [
    CommonModule,
    CommonModule,    
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule, 
    MatTableModule,
    MatPaginatorModule,
    MaterialTimePickerModule,
    MatExpansionModule,
    MatDividerModule,
    MatMenuModule
  ]
})
export class AssigntransportModule { }
