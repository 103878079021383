
<app-sidebar ></app-sidebar>
<app-footer></app-footer>

<!-- <mat-sidenav-container >
            <mat-sidenav
              #sidenav
              fixedInViewport="true"
              mode="side"
              opened
            >
            <app-sidebar (toggleMenu)="toggleMenu()"
            [isExpanded]="isExpanded">
        </app-sidebar>
            </mat-sidenav>
        <mat-sidenav-content >
          <div class="content-container">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container> -->
    