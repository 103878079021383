import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { MatTreeModule } from '@angular/material/tree';
import { ExpenseComponent } from './expense.component';



@NgModule({
  declarations: [ExpenseComponent],
  imports: [
    CommonModule,    
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule, 
    MatTableModule,
    MatPaginatorModule,
    MaterialTimePickerModule,
    MatExpansionModule,
    MatDividerModule,
    MatMenuModule,
    MatTreeModule
  ]
})
export class ExpenseModule { 
  
}
