import { Injectable } from '@angular/core';
import { State } from '../commonmodel/state';
import { District } from '../commonmodel/district';
import { City } from '../commonmodel/city';
import { Student } from '../master/student/student';
import { Staff } from '../master/staff/staff';
import { Designation } from '../master/designation/designation';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }
  pinmobilevalidattion(data) {

    return data.target.value = data.target.value.replace(/[^0-9]/g, '');
  }
  emailnotallowed() {

  }
  nospecialcharacternumber(data) {
    return data.target.value = data.target.value.replace(/[^A-Za-z ]/g, '');
  }

  nospecialcharacter(data) {
    return data.target.value = data.target.value.replace(/[^A-Za-z0-9- ]/g, '');
  }

  mobilevalidation() {

  }
  onlynumberallowed(data) {
    return data.target.value = data.target.value.replace(/[^0-9]/g, '');
  }

 
  getstate(countryid: number, states: State[]):State[] {
   return states.filter(x => Number(x.countryid) == countryid)
  }
  getdistrict(stateid: number, districts: District[]):District[] {
   return districts.filter(x => Number(x.stateid) == stateid)
  }
  getcity(districtid: number, cities: City[]):City []{
    return cities.filter(x => Number(x.districtid) == districtid)
  }
  getstudent(grade: string, students: Student[]):Student []{
    return students.filter(x => x.grade == grade)
  }
  getdepartment(departmentname: string, staffs: Staff[]):Staff []{
    return staffs.filter(x => x.departmentname == departmentname)
  }
  getdesignation(departmentid: bigint, designations: Designation[]):Designation []{
    return designations.filter(x=>x.departmentid==departmentid);
  }
  getstaff(designationname: string, staffs: Staff[]):Staff []{
    return staffs.filter(x=>x.designationname==designationname);
  }
  getstudentforuserprofile(studentid: bigint, students: Student[]):Student []{
    return students.filter(x=>x.id==studentid);
  }
  getstaffbydepartment(departmentname: string, staffs: Staff[]):Staff []{
    return staffs.filter(x=>x.departmentname==departmentname);
  }
  getstudents(grade: string, students: Student[]):Student []{
    return students.filter(x=>x.grade==grade);
  }

}
