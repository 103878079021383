<p>Sign Up</p>
<form [formGroup]="myaccountform">
    <table class="example-full-width" cellspacing="0">

        <tr>
            <!-- <td><mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>User Type</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let myaccounttype of myaccounttypes" [value]="myaccounttype">
                            {{myaccounttype.type}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="myaccountform.get('type').hasError('required')">Please choose user type</mat-error>
                    <mat-hint></mat-hint>
                </mat-form-field></td> -->

            <td><mat-form-field class="example-full-width">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstname">
                    <mat-error *ngIf="myaccountform.get('firstname').hasError('required')">
                        First Name <strong>required</strong></mat-error>
                </mat-form-field></td>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Middle Name</mat-label>
                    <input matInput formControlName="middlename">

                </mat-form-field></td>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastname">
                    <mat-error *ngIf="myaccountform.get('lastname').hasError('required')">
                        Last Name <strong>required</strong></mat-error>
                </mat-form-field></td>



        </tr>
        <tr>
            <td><mat-form-field class="example-full-width">
                    <mat-label>User Name</mat-label>
                    <input matInput formControlName="username">
                    <mat-error *ngIf="myaccountform.get('username').hasError('required')">
                        Username <strong>required</strong></mat-error>
                </mat-form-field></td>
            <td>
                <mat-form-field class="example-full-width" >
                    <mat-label>Password</mat-label>
                    <input type="password" matInput formControlName="password">
                    <ng-container *ngFor="let error of error_messages.password " >
                        <mat-error *ngIf="myaccountform.get('password').hasError(error.type) && data==undefined">{{error.message}}
                        </mat-error>
                    </ng-container>


                </mat-form-field>
            </td>
            <td>
                <mat-form-field class="example-full-width" >
                    <mat-label>Confirm Password</mat-label>
                    <input type="password" matInput formControlName="confirmpassword">
                    <ng-container *ngFor="let error of error_messages.confirmpassword">
                        <mat-error *ngIf="myaccountform.get('confirmpassword').hasError(error.type) && data==undefined">{{error.message}}
                        </mat-error>
                    </ng-container>

                    <div style="color: red;" *ngIf="myaccountform.errors?.['PasswordNoMatch']">Confirm Password not
                        matched.
                    </div>
                </mat-form-field>
            </td>

        </tr>
        <tr>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Mobile</mat-label>
                    <span matPrefix>+91 &nbsp;</span>
                    <input type="tel" matInput placeholder="9178526541" formControlName="mobileno">
                    <mat-icon matSuffix>mode_edit</mat-icon>
                    <mat-error *ngIf="myaccountform.get('mobileno').hasError('required')">
                        Mobile No <strong>required</strong></mat-error>
                </mat-form-field></td>

            <td><mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com">
                    <mat-error
                        *ngIf="myaccountform.get('email').hasError('email') && !myaccountform.get('email').hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="myaccountform.get('email').hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error></mat-form-field></td>

        </tr>

        <tr>

            <!-- <td>
                <button *ngIf="ispaymentsuccess"  mat-raised-button (click)="savemyaccount()">
                    Pay
                </button>
            </td> -->
            <!-- <td> <button *ngIf="issavebuttondis" mat-raised-button (click)="savemyaccount()">
                    {{buttonname}}
                </button></td> -->
            <td><button mat-raised-button (click)="savemyaccount()" *ngIf="data==undefined"  >
                    {{buttonname}}
                </button></td>
            <td colspan="2"><button mat-raised-button type="submit" class="Cancel-btn"
                    (click)="cancel()">Cancel</button></td>
        </tr>
    </table>
</form>