import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { pages } from '../global';
import { Grade } from '../commonmodel/grade';
import { Religion } from '../commonmodel/religion';
import { Gender } from '../commonmodel/gender';
import { City } from '../commonmodel/city';
import { Country } from '../commonmodel/country';
import { District } from '../commonmodel/district';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor(private http: HttpClient) { }
  bindreligion() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindreligion).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindgender(): Observable<object> {
    return this.http.get(environment.apiURL + pages.bindgender).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindgrade(): Observable<object> {
    return this.http.get(environment.apiURL + pages.bindgrade).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindcountry() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindcountry).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindcity(): Observable<object> {
    return this.http.get(environment.apiURL + pages.bindcity).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  binddistrict(): Observable<object> {
    return this.http.get(environment.apiURL + pages.binddistrict).pipe(map((res) => {
      
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindstate(): Observable<object> {
    return this.http.get(environment.apiURL + pages.bindstate).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindcategory() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindcategory).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindbloodgroup() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindbloodgroup).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }

  binddepartment() : Observable<object>{
    return this.http.get(environment.apiURL + pages.binddepartment).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  binddesignation() : Observable<object>{
    return this.http.get(environment.apiURL + pages.binddesignation).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindstaff() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindstaff).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }

  bindroute() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindroute).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }

  binduserprofiletype() : Observable<object>{
    return this.http.get(environment.apiURL + pages.binduserprofiletype).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }

  bindattendance() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindattendance).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindstudent() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindstudent).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }

  bindfeetype() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindfeetype).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindsession() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindsession).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }
  bindtransport() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindtransport).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }

  bindmenupages() : Observable<object>{
    return this.http.get(environment.apiURL + pages.bindmenupages).pipe(map((res) => {
      // const obj = [];
      // for (const key in res) {
      //   if (res.hasOwnProperty(key)) {
      //     obj.push({ ...res[key], id: key });
      //   }
      // }
      return res;
    }), catchError((error) => { return throwError(error) }));
  }



}
