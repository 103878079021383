import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. */
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ValidateService } from 'src/app/service/validate.service';
import { BasudevayService } from 'src/app/service/basudevay.service';
import { httpmethod, pages } from 'src/app/global';
import { AlertService } from 'src/app/service/alert.service';
import { DropdownService } from 'src/app/service/dropdown.service';
import { MatAccordion } from '@angular/material/expansion';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs/internal/Subject';

import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MyAccount, MyAccountType } from './myaccount';
import { passwordMatchValidator } from 'src/app/common/confirmpasswordvalidator';
import { HttpHeaders, HttpParams } from '@angular/common/http';

const phoneEmailRegex = /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;
@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css'],
  //standalone:true,
  //imports: [MatButtonModule]
})
export class MyaccountComponent {
  error_messages = {
    'password': [
    { type: 'required', message: 'Password is required.' },
    { type: 'minlength', message: 'Password length min 6.' },
    { type: 'maxlength', message: 'Password length max 30.' }
  ],
  'confirmpassword': [
    { type: 'required', message: 'Password is required.' },
    { type: 'minlength', message: 'Password length min 6.' },
    { type: 'maxlength', message: 'Password length max 30.' }
  ],
}
  keys: string[];
  dataSheet = new Subject();
  myaccountform: FormGroup;
  isExcelFile: boolean;
  checked: boolean = false;
  editid: bigint;
  buttonname: string;
  myaccount: MyAccount;
  myaccounts: MyAccount[] = [];
  myaccounttypes: MyAccountType[] = [];
  exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 24 };

  minDate: Date;
  maxDate: Date;
  colorToggle: ThemePalette = "primary";
  backgroundColorToggle: ThemePalette = "accent";
  issavebuttondis: boolean = true;
  ispaymentsuccess: boolean = false;

  constructor(private basudevayservice: BasudevayService, private alertservice: AlertService, private dropdownservice: DropdownService, private customvalidate: ValidateService, private dialogref: MatDialogRef<MyaccountComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: MyAccount) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
  }


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('myaccounttable') table: ElementRef;

  ngOnInit(): void {
    this.myaccountform = new FormGroup({
      firstname: new FormControl(null, [Validators.required, Validators.pattern('^[A-Za-z ]+$')]),
      middlename: new FormControl(null),
      lastname: new FormControl(null),
      username: new FormControl(null, [Validators.required, Validators.pattern(phoneEmailRegex)]),
      email: new FormControl(null, [Validators.required, , Validators.email]),
      mobileno: new FormControl(null, [Validators.required, , Validators.maxLength(10)]),
      password: new FormControl(null, [Validators.required]),
      confirmpassword: new FormControl(null, [Validators.required]),
      //type: new FormControl(null, [Validators.required]),
    },
      {
        validators: passwordMatchValidator.bind(this)
      }
    );
    //this.binddropdown();
    this.buttonname = 'Save';
    if (localStorage.getItem("currentuser") != null) {
      this.issavebuttondis = false;
      this.bindfrommyaccount();
    }
    
  }
  ngAfterViewInit() {
   
  }
  public cancel(): void { // To cancel the dialog window
    this.dialogref.close();
  }

  setformvaluetomodel(): MyAccount {
    const myaccount: MyAccount = this.myaccountform.value;
    return myaccount;
  }
  savemyaccount() {

    var httpparam = new HttpParams({

      fromObject: {
        username: this.myaccountform.value.username
        // mobileno: this.myaccountform.value.mobileno,
        // emailid: this.myaccountform.value.emailid,
      }

    });
    var headers = new HttpHeaders({ 'username': this.myaccountform.value.username});

    let paymentreturn = this.basudevayservice.requestapi(httpmethod.get, pages.GetPaymentByUserid, null, null, null, httpparam).subscribe(
      (res) => {
        if (res == null) {
          console.log(res);
          this.alertservice.openSnackBar('Do Payment.', "Pending");

        }
        else {
          if (this.myaccountform.valid) {
            this.myaccount = this.setformvaluetomodel();

            this.basudevayservice.requestapi(httpmethod.post, pages.signup, this.myaccount,null,null,null,headers).subscribe(
              (res) => { console.log(res) },
              (err) => { console.log(err) },
              () => { console.log("Api has has been completed.") }
            );
            this.alertservice.openSnackBar('myaccount Created Successfully.', "Created");
            this.myaccountform.reset();

          }
          else {
            this.alertservice.openSnackBar('Fill mandatory fields.', "Must to be filled");
          }

        }

      },
      (err) => { console.log(err) },
      () => { console.log("Api has has been completed.") }
    );


    // this.alertservice.openSnackBar('Passsword reset Successfully.', "Forget password");
    //this.myaccountform.reset();
    this.dialogref.close();
  }

  onChangeHour(event) {
    console.log('event', event);
  }
  nospecialcharacternumber(data) {
    return this.customvalidate.nospecialcharacternumber(data);
  }
  pinmobilevalidattion(data) {
    return this.customvalidate.pinmobilevalidattion(data);
  }


  geteditdata(data: MyAccount) {
    console.log(data);
    this.buttonname = 'Edit';
    this.editid = data.id;
    let myaccount = this.myaccounts.find((s) => { return s.id = data.id });


    this.myaccountform.patchValue({
      firstname: myaccount.firstname,
      middlename: myaccount.middlename,
      lastname: myaccount.lastname,
      username: myaccount.username,
      password: myaccount.password,
      email: myaccount.email,
      mobileno: myaccount.mobileno,
      type: this.myaccounttypes.find(x => x.type = myaccount.type)
    });
    this.accordion.openAll()
  }

  binddropdown() {
    this.dropdownservice.binduserprofiletype().subscribe(
      (res: MyAccountType[]) => {
        this.myaccounttypes = res
      });
  }

  bindfrommyaccount() {
    this.myaccountform.patchValue({
      firstname: this.data.firstname,
      middlename: this.data.middlename,
      lastname: this.data.lastname,
      username: this.data.username,
      password: this.data.password,
      email: this.data.email,
      mobileno: this.data.mobileno,
      type: this.myaccounttypes.find(x => x.type = this.data.type)
    });
  }

}
