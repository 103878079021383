import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { map, of } from 'rxjs';
import { httpmethod, pages } from 'src/app/global';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { BasudevayService } from 'src/app/service/basudevay.service';
import { UploadimagesService } from 'src/app/service/uploadimages.service';
import { imageRetrieve } from '../home/home';
import { CrossComponentService } from 'src/app/service/crosscomponent.service';
import { School } from 'src/app/companyprofile/school/school';
import { Registration } from 'src/app/admission/registration/registration';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MyaccountComponent } from 'src/app/account/myaccount/myaccount.component';
import { AlertService } from 'src/app/service/alert.service';
import { UserProfile } from 'src/app/user-profile/user-profile';
import { MyAccount } from 'src/app/account/myaccount/myaccount';
import { HttpParams } from '@angular/common/http';
import { DialogRef } from '@angular/cdk/dialog';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
declare interface childRouteInfo {
  path: string;
  pagetitle: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', },
  { path: '/user-profile', title: 'User Profile', icon: 'person', class: '' },
  { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
  { path: '/maps', title: 'Maps', icon: 'location_on', class: '' },

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent {
  @Input() isExpanded: boolean;
  @Output() toggleMenu = new EventEmitter();
  isChildren: boolean;
  menuItems: any = [];
  response: any;
  children: any = [];
  loginstatus: boolean;
  hidden = false;
  imgsrc: string;
  school: School;
  matbadge: number;
  signup: MyAccount;
  username:string
  welcomeusername:string
  
  constructor(private basudevayservice: BasudevayService, private authenticationservice: AuthenticationService, private uploadservice: UploadimagesService, private crosscomponentservice: CrossComponentService, private router: Router, private dialog: MatDialog, private alertservice: AlertService) {
    // this.matbadge = 20;
    this.basudevayservice.requestapi(httpmethod.get, pages.getread).pipe().subscribe(
      (res: Registration[]) => {
        this.matbadge = res.length;
      });
  }
  tree = { id: 0, name: "root", children: [] };

  addChild = (obj, parent) => {
    if (obj.parentid === parent.id) {
      parent.children.push({ ...obj, children: [] });
    } else {
      parent.children.forEach((item) => this.addChild(obj, item));
    }
  };
  buildTree = (arr) => arr.forEach(obj => this.addChild(obj, this.tree));



  ngOnInit() {
    //this.getschool(); 
    this.getmenu();

    // this.basudevayservice.requestapi(httpmethod.get, pages.menumasterwithoutuserid).subscribe((data: any[]) => {
    //   data.sort((f, n): number => {
    //     if (f.id < n.id) return -1;
    //     if (f.id > n.id) return 1;

    //     return 0;
    //   });
    //   this.buildTree(data);
    //   console.log(this.tree.children);
    //   this.menuItems = this.tree.children;
    // },
    //   (error) => {

    //   },
    //   () => {

    //   });
    this.getschool();
    this.getlogoandname();
    if (localStorage.getItem('currentuser') != null) {
      this.username=JSON.parse(localStorage.getItem('currentuser')).username

    }
  }
  logout() {
    this.getmenu();
    this.authenticationservice.logout();

  }

  getmenu() {
    this.authenticationservice.getdefaultmenu();
    if (localStorage.getItem('currentuser') != null) {
      this.authenticationservice.logout();

    }    

    this.authenticationservice.menuItems.subscribe((menu) => {
      this.menuItems = menu;
    })
    this.authenticationservice.loggedin.subscribe(status => {
      this.loginstatus = status;
    })
    this.authenticationservice.usrname.subscribe(username => {
      this.welcomeusername = username;
    })

    this.authenticationservice.school.subscribe(school => {
      this.imgsrc = 'data:image/jpg;base64,' + school.file
    })

  }
  ngOnDestroy() {
    this.authenticationservice.menuItems.unsubscribe();
  }


  toggleBadgeVisibility() {
    // this.hidden = !this.hidden;
    if (localStorage.getItem('currentuser') != null) {
      this.basudevayservice.requestapi(httpmethod.put, pages.updateread, { isread: true }).pipe().subscribe(
        (res) => {
        });
      this.hidden = true;
      this.router.navigate(['/admission/registrationdetail']);
    }

  }
  getlogoandname() {
    this.uploadservice.getFiles().subscribe((res: imageRetrieve[]) => {
      res.forEach((item) => {
        if (item.imagetype.toLocaleLowerCase() == 'schoollogo') {
          this.imgsrc = 'data:image/jpg;base64,' + item.imagedata
        }
      });
    });

  }
  getschool() {
    this.crosscomponentservice.school.subscribe((res) => {
      this.school = res;
    });

  }
  getaccoutdialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    if (localStorage.getItem('currentuser') != null) {
      this.getaccountdetails();
      this.dialog.open(MyaccountComponent, {
        width: '700px',
        height: '350px',
        enterAnimationDuration,
        exitAnimationDuration,
        data: this.signup
      });
      
    }
    else {
      this.alertservice.openSnackBar('Login First.', "Login");
    }
  }
  getaccountdetails() {
    var httpparam = new HttpParams({

      fromObject: {
        username:JSON.parse(localStorage.getItem("currentuser")).username
      }

    });
    this.basudevayservice.requestapi(httpmethod.get, pages.getsignup,null,null,null,httpparam).subscribe(
      (res: MyAccount) => {
        this.signup = res;
      });
  }
  

}
