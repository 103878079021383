import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { pages } from '../global';

@Injectable({
  providedIn: 'root'
})
export class UploadimagesService {

  constructor(private http: HttpClient) { }
  upload(file: File, filetype: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('filetype', filetype)
    // const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    // headers.append('Accept', 'application/json');


    const req = new HttpRequest('POST', `${environment.apiURL + pages.uploadmultipleimages}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    // const httpOptions = {
    //   'responseType'  : 'arraybuffer' as 'json'
    //   //'responseType': 'blob' as 'json'        //This also worked
    // };

    return this.http.get(environment.apiURL + pages.getallimage).pipe(map((res) => {

      return res;
    }), catchError((error) => {
      console.log(error);
      return throwError(error)
    }));
  }
  getFilesByFileType(filetype:string): Observable<any> {
    // const httpOptions = {
    //   'responseType'  : 'arraybuffer' as 'json'
    //   //'responseType': 'blob' as 'json'        //This also worked
    // };

    return this.http.get(environment.apiURL + pages.getimagebyfiletype + '/' + filetype).pipe(map((res) => {

      return res;
    }), catchError((error) => {
      console.log(error);
      return throwError(error)
    }));
  }
}
