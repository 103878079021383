import { environment } from "src/environments/environment";

export enum httpmethod{
    get="get",
    post="post",
    put="put",
    delete="delete",
    patch="patch"  
  }

  export const pages = {
    root: '/',
    defaultRoute: '/',
    home: '/recipes',
    getdefaultmenu:'/api/menumaster/getdefaultmenu',
    getmenubyuser:'/api/menumaster/getmenubyuser',
    bindreligion:'/api/Religion/getreligion',
    bindgender:'/api/Gender/getgender',
    bindgrade:'/api/grade/getgrade',
    bindcountry:'/api/country/getcountry',
    bindcity:'/api/city/getcity',
    binddistrict:'/api/district/getdistrict',
    bindstate:'/api/state/getstate',
    bindcategory:'/api/category/getcategory',
    bindbloodgroup:'/api/bloodgroup/getbloodgroup',
    binddepartment:'/api/department/getdepartment',
    binddesignation:'/api/designation/getdesignation',
    bindroute:'/api/route/getroute',
    bindtransport:'/api/transport/gettransport',
    bindstaff:'/api/staff/getstaff',
    binduserprofiletype:'/api/UserProfileType/GetuserProfileType',
    bindattendance:'/api/attendance/getattendance',
    bindstudent:'/api/student/getstudent',
    bindfeetype:'/api/feetype/getfeetype',
    bindsession:'/api/Financialyear/Getsession',
    bindmenupages:'/api/menumaster/getmenupages',
    
    


    savestudent:'/api/Student/AddStudent',
    savebulkstudent:'/api/Student/AddBulkStudent',
    editstudent:'/api/Student/UpdateStudent',
    deletestudent:'/api/Student/DeleteStudent',
    getstudentbyid:'/api/Student/GetStudentById',
    getallstudent:'/api/Student/GetStudent',


    savestaff:'/api/staff/Addstaff',
    savebulkstaff:'/api/staff/AddBulkstaff',
    editstaff:'/api/staff/Updatestaff',
    deletestaff:'/api/staff/Deletestaff',
    getstaffbyid:'/api/staff/GetstaffById',
    getallstaff:'/api/staff/Getstaff',

    saveleavemanagement:'/api/leavemanagement/Addleavemanagement',
    savebulkleavemanagement:'/api/leavemanagement/AddBulkleavemanagement',
    editleavemanagement:'/api/leavemanagement/Updateleavemanagement',
    deleteleavemanagement:'/api/leavemanagement/Deleteleavemanagement',
    getleavemanagementbyid:'/api/leavemanagement/GetleavemanagementById',
    getalleavemanagement:'/api/leavemanagement/Getleavemanagement',


    savedepartment:'/api/department/Adddepartment',
    savebulkdepartment:'/api/department/AddBulkdepartment',
    editdepartment:'/api/department/Updatedepartment',
    deletedepartment:'/api/department/Deletedepartment',
    getdepartmentbyid:'/api/department/GetdepartmentById',
    getalldepartment:'/api/department/Getdepartment',

    savefeetype:'/api/feetype/Addfeetype',
    savebulkfeetype:'/api/feetype/AddBulkfeetype',
    editfeetype:'/api/feetype/Updatefeetype',
    deletefeetype:'/api/feetype/Deletefeetype',
    getfeetypebyid:'/api/feetype/GetfeetypeById',
    getallfeetype:'/api/feetype/Getfeetype',


    saveVia:'/api/Route/AddRoute',
    savebulkVia:'/api/Route/AddBulkRoute',
    editVia:'/api/Route/UpdateRoute',
    deleteVia:'/api/Route/DeleteRoute',
    getViabyid:'/api/Route/GetRouteById',
    getallVia:'/api/Route/GetRoute',



    savedesignation:'/api/designation/Adddesignation',
    savebulkdesignation:'/api/designation/AddBulkdesignation',
    editdesignation:'/api/designation/Updatedesignation',
    deletedesignation:'/api/designation/Deletedesignation',
    getdesignationbyid:'/api/designation/GetdesignationById',
    getalldesignation:'/api/designation/Getdesignation',



    savetransport:'/api/transport/Addtransport',
    savebulktransport:'/api/transport/AddBulktransport',
    edittransport:'/api/transport/Updatetransport',
    deletetransport:'/api/transport/Deletetransport',
    gettransportbyid:'/api/transport/GettransportById',
    getalltransport:'/api/transport/Gettransport',




    saveassigntransport:'/api/assigntransport/Addassigntransport',
    savebulkassigntransport:'/api/assigntransport/AddBulkassigntransport',
    editassigntransport:'/api/assigntransport/Updateassigntransport',
    deleteassigntransport:'/api/assigntransport/Deleteassigntransport',
    getassigntransportbyid:'/api/assigntransport/GetassigntransportById',
    getallassigntransport:'/api/assigntransport/Getassigntransport',



    saveleave:'/api/leave/Addleave',
    editleave:'/api/leave/Updateleave',
    deleteleave:'/api/leave/Deleteleave',
    getleavesbyid:'/api/leaves/GetleavesById',
    getleaves:'/api/leaves/Getleaves',


    saveapplyleaves:'/api/leaves/Addapplyleaves',
    editapplyleaves:'/api/leaves/Updateapplyleaves',
    deleteapplyleaves:'/api/leaves/Deleteapplyleaves',
    getapplyleavesbyid:'/api/leaves/GetapplyleavesById',
    GetapplyLeaves:'/api/leaves/Getapplyleaves',

    saveapproveleave:'/api/leaveapprove/AddleaveApprove',
    editapproveleave:'/api/leaveapprove/UpdateleaveApprove',
    deleteapproveleave:'/api/leaveapprove/DeleteleaveApprove',
    getapproveleavebyid:'/api/leaveapprove/GetleaveApproveById',
    getallapproveleave:'/api/leaveapprove/GetleaveApprove',




    saveuserprofile:'/api/userprofile/Adduserprofile',
    edituserprofile:'/api/userprofile/Updateuserprofile',
    deleteuserprofile:'/api/userprofile/Deleteuserprofile',
    getuserprofilebyid:'/api/userprofile/GetuserprofileById',
    getalluserprofile:'/api/userprofile/Getuserprofile',


    savemarking:'/api/marking/Addmarking',
    editmarking:'/api/marking/Updatemarking',
    deletemarking:'/api/marking/Deletemarking',
    getmarkingbyid:'/api/marking/GetmarkingById',
    getallmarking:'/api/marking/Getmarking',



    savesalary:'/api/salary/Addsalary',
    editsalary:'/api/salary/Updatesalary',
    deletesalary:'/api/salary/Deletesalary',
    getsalarybyid:'/api/salary/GetsalaryById',
    getallsalary:'/api/salary/Getsalary',



    savefees:'/api/fees/Addfees',
    editfees:'/api/fees/Updatefees',
    deletefees:'/api/fees/Deletefees',
    getfeesbyid:'/api/fees/GetfeesById',
    getallfees:'/api/fees/Getfees',


    getfeesreport:'/api/reports/getfeesreport',
    getsalaryreport:'/api/reports/getsalaryreport',
    getattendancereport:'/api/reports/getattendancereport',
    getleavesreport:'/api/reports/getleavesreport',


    login:'/api/login/login',
    forgetpassword:'/api/login/forgetpassword',
    addandsendotp:'/api/login/addandsendotp',
    signup:'/api/login/signup',
    getsignup:'/api/login/getsignup',
    validateotp:'/api/login/validateotp',
    
    getadmissiondashboard:'/api/dashboard/getadmissiondashboard',
    getregistrationdashboard:'/api/dashboard/getregistrationdashboard',
    getfeesdashboard:'/api/dashboard/getfeesdashboard',
    getleavesdashboard:'/api/dashboard/getleavesdashboard',



    uploadimage:'/api/ImageUpload/UploadImage',
    uploadmultipleimages:'/api/ImageUpload/uploadmultipleimages',
    getallimage:'/api/ImageUpload/GetAllImage',
    getimagebyfiletype:'/api/ImageUpload/getimagebyfiletype',
    
    saveschool:'/api/school/Addschool',
    editschool:'/api/school/Updateschool',
    deleteschool:'/api/school/Deleteschool',
    getschoolbyid:'/api/school/GetschoolById',
    getallschool:'/api/school/Getschool',

    getallfeestructure:'/api/feestructure/getallfeestructure',
    getallenquirytext:'/api/enquiry/getallenquirytext',
    getallcontactus:'/api/contactus/getallcontactus',
    getallaboutus:'/api/aboutus/getallaboutus',


    getallregistration:'/api/registration/getallregistration',
    saveregistration:'/api/registration/saveregistration',
    getread:'/api/registration/getread',
    updateread:'/api/registration/updateread',


    GetPaymentByUserid:'/api/Payment/GetPaymentByUserid',

    GetsalaryPrint:'/api/print/GetsalaryPrint',
    GetfeesPrint:'/api/Print/GetfeesPrint',
    Gettransfercertificatesprint:'/api/Print/Gettransfercertificatesprint',
    Getstudentresultprint:'/api/Print/Getstudentresultprint',
    Getidcardprint:'/api/Print/Getidcardprint',

    transfercertificates:'/api/Student/transfercertificates',
    getstudenttransfercertificates:'/api/Student/getstudenttransfercertificates',
    updatestudenttransfercertificates:'/api/Student/updatestudenttransfercertificates',


    Studentresult:'/api/Student/Studentresult',
    getStudentresult:'/api/Student/getStudentresult',
    updateStudentresult:'/api/Student/updateStudentresult',


    idcard:'/api/Student/idcard',
    getidcard:'/api/Student/getidcard',
    

    saveMarks:'/api/Marks/AddMarks',
    editMarks:'/api/Marks/UpdateMarks',
    deleteMarks:'/api/Marks/DeleteMarks',
    getMarksbyid:'/api/Marks/GetMarksById',
    getallMarks:'/api/Marks/GetMarks',


    saveNotification:'/api/Notification/AddNotification',
    editNotification:'/api/Notification/UpdateNotification',
    deleteNotification:'/api/Notification/DeleteNotification',
    getNotificationbyid:'/api/Notification/GetNotificationById',
    getallNotification:'/api/Notification/GetNotification',



    saveExpense:'/api/Expense/AddExpense',
    editExpense:'/api/Expense/UpdateExpense',
    deleteExpense:'/api/Expense/DeleteExpense',
    getExpensebyid:'/api/Expense/GetExpenseById',
    getallExpense:'/api/Expense/GetExpense',

  }
  
  