// import { AbstractControl, FormGroup } from "@angular/forms";
// export function passwordMatchValidator(formGroup: FormGroup) {
//   // return frm.controls['password'].value === frm.controls['confirmpassword'].value ? null : {'mismatch': true};

//   const { value: password } = formGroup.get('password');
//   const { value: confirmPassword } = formGroup.get('confirmpassword');
//   return password === confirmPassword ? null : { PasswordNoMatch: true };
// }



import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const passwordMatchValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password == control.value.confirmpassword
    ? null
    : { PasswordNoMatch: true };
};

