import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable, empty } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../service/alert.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private alertservice: AlertService, private _router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentuser = JSON.parse(localStorage.getItem('currentuser'));
    let currentschool = localStorage.getItem('school') == 'undefined' ? null : JSON.parse(localStorage.getItem('school'));

    if (!request.url.includes("/ImageUpload") && !request.url.includes("/school") && !request.url.includes("/login") && !request.url.includes("/signup") && !request.url.includes("/getmenubyuser") && request.method=='POST' && currentschool == null) {
      
      this.alertservice.openSnackBar('School is not exists.', "First Create School");
      this._router.navigate(['/master/school']);
      return  empty();
      
    }
    if (currentuser && currentuser.token) {
      request = request.clone({
        setHeaders: {
          'userid': currentuser.id.toString(),
          'username': currentuser.username.toString(),
          'userdb': currentuser.username,
          "schoolname": currentschool?.name ?? '',
          "schoolid": currentschool?.id.toString() ?? '0',
          "schoollinkid": currentschool?.schoollinkid.toString() ?? '0',
          Authorization: `Bearer ${currentuser.token}`,
          "dbinfoid": currentuser.dbinfoid.toString() ?? '0',
          "X-Version": environment.apiver.toString()
        }
      });
      
    }
    // if (currentschool) {
    //   request = request.clone({
    //     setHeaders: {
    //       "schoolname": currentschool.name,
    //       "schoolid": currentschool.id        
    //      }
    //   });
    // }

    return next.handle(request);
    
    
  }
}


// @Injectable()
// export class AuthInterceptorService implements HttpInterceptor {

//   constructor(private _localStorageService: LocalStorageService, private _router: Router, private _location: Location) { }


//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (req.url.includes("/login")) {
//       return next.handle(req);
//     }
//     let authToken: LocalStorage = this._localStorageService.getItem('auth-token');
//     if (authToken) {
//       return next.handle(
//         req.clone({
//           headers: req.headers.append('Authorization', 'jwt ' + authToken.value)
//         })
//       ).pipe(tap((response: HttpResponse<any>) => {
//         console.log(response);
//         if (response instanceof HttpResponse) {
//           if (response.body.status == 401) {
//             this._router.navigate([Routes.LOGIN]);
//             return response;
//           }
//         }
//       }));
//     }
//     this._router.navigate(['/login']);
//     return empty();
//   }
// }