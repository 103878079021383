import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { httpmethod, pages } from '../global';
import { Grade } from '../commonmodel/grade';
import { Religion } from '../commonmodel/religion';
import { Gender } from '../commonmodel/gender';
import { City } from '../commonmodel/city';
import { Country } from '../commonmodel/country';
import { District } from '../commonmodel/district';
import { Home } from '../components/home/home';
import { School } from '../companyprofile/school/school';
import { BasudevayService } from './basudevay.service';

@Injectable({
  providedIn: 'root'
})
export class CrossComponentService {
  school: any;

  constructor(private basudevayservice: BasudevayService, private http: HttpClient) {
    this.school = new BehaviorSubject<any>(null);
    this.getschool();

  }

  getschool() {
    this.basudevayservice.requestapi(httpmethod.get, pages.getallschool).pipe().subscribe(
      (res: School[]) => {
        this.school.next(res[0]);
        localStorage.setItem('school', JSON.stringify(res[0]));
      });

  }


}