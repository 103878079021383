import { Component, OnInit } from '@angular/core';
import { School } from 'src/app/companyprofile/school/school';
import { httpmethod, pages } from 'src/app/global';
import { BasudevayService } from 'src/app/service/basudevay.service';
import { CrossComponentService } from 'src/app/service/crosscomponent.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  usrname:string
  constructor() {

    
   }
  ngOnInit(): void {
    
  }
 
  
}
