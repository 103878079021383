import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnquiryComponent } from './admission/enquiry/enquiry.component';
import { RegistrationComponent } from './admission/registration/registration.component';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentsModule } from './components/components.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdmissionModule } from './admission/admission.module';


import { SettingComponent } from './settings/setting/setting.component';
import { LoginComponent } from './account/login/login.component';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { AttendanceModule } from './attendance/attendance.module';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { LayoutModule } from '@angular/cdk/layout';
import { MasterModule } from './master/master.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MapsComponent } from './maps/maps.component';
import { LeavesModule } from './leaves/leaves.module';
import { AboutusComponent } from './companyprofile/aboutus/aboutus.component';
import { ContactusComponent } from './companyprofile/contactus/contactus.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { GoogleMapsModule } from '@angular/google-maps';



import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';

import { AsyncPipe, CommonModule } from '@angular/common';
import { AssigntransportModule } from './transport/assigntransport.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { TransactionModule } from './transaction/transaction.module';

import { ReportModule } from './report/report.module';
import { LoginguardService } from './service/loginguard.service';
import { AuthenticationService } from './service/authentication.service';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';

import { ForgetpasswordComponent } from './account/forgetpassword/forgetpassword.component';
import { MyaccountComponent } from './account/myaccount/myaccount.component';
import { CompanyprofileModule } from './companyprofile/companyprofile.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OtpComponent } from './account/otp/otp.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { SalaryprintComponent } from './print/salaryprint/salaryprint.component';
import { FeesprintComponent } from './print/feesprint/feesprint.component';
import { OperationsModule } from './operations/operations.module';
import { SettingsModule } from './settings/settings.module';
import { TransfercertificatesprintComponent } from './print/transfercertificatesprint/transfercertificatesprint.component';
import { StudentresultprintComponent } from './print/studentresultprint/studentresultprint.component';

import { IdcardprintComponent } from './print/idcardprint/idcardprint.component';
import { ExpenseModule } from './expense/expense.module';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    EnquiryComponent,
    RegistrationComponent,
    MyaccountComponent,
    SettingComponent,
    LoginComponent,    
    DashboardComponent,
    MapsComponent, AboutusComponent, ContactusComponent, ForgetpasswordComponent, OtpComponent,  SalaryprintComponent, FeesprintComponent, TransfercertificatesprintComponent, StudentresultprintComponent,  IdcardprintComponent 

  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    AdmissionModule,
    AttendanceModule,
    MasterModule,
    LeavesModule,
    AssigntransportModule,
    TransactionModule,
    UserProfileModule,
    ReportModule,
    CompanyprofileModule,
    OperationsModule,
    SettingsModule,
    ExpenseModule,
   
    NoopAnimationsModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatGridListModule,
    MatCardModule,
    LayoutModule,
    NgImageSliderModule,
    GoogleMapsModule,
    MatSnackBarModule,
    MatDividerModule,
    //MyaccountComponent,
    GoogleMapsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    AsyncPipe
    
    


  ],
  providers: [LoginguardService, AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
